// import feathers from '@feathersjs/client';
import feathers from '@feathersjs/feathers';
import { StateContainer } from 'src/lib/state';
import restClient from './rest';

import authenticateService from './services/authenticate';
import storyService from './services/story';
import categoryService from './services/category';
import categoryContentService from './services/category-content';
import chapeterService from './services/chapter';
import instructionsService from './services/instructions';

import { attachAuthHeader, redirectUnAuthorized } from './hooks';

const app = feathers();

const stateContainer = new StateContainer({
  authenticated: false
});

app.set('stateContainer', stateContainer);

app.configure(restClient);

app.configure(authenticateService);
app.configure(storyService);
app.configure(categoryService);
app.configure(categoryContentService);
app.configure(chapeterService);
app.configure(instructionsService);

app.hooks({
  before: {
    all: [attachAuthHeader]
  },
  error: {
    all: [redirectUnAuthorized]
  }
});

export default app;
