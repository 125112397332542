import restClient from '@feathersjs/rest-client';
import FetchService from '@feathersjs/rest-client/lib/fetch';

// The Ficto API throws string errors instead of
// JSON Errors, so we have to extend a service
// to handle this.

const convertError = (error, response) => {
  const apiError = new Error(error);
  apiError.type = 'ApiError';
  apiError.name = response.statusText;
  apiError.code = response.status;
  return apiError;
};

class FictoService extends FetchService {
  checkStatus(response) {
    if (response.ok) {
      return response;
    }

    const contentType = response.headers.get('content-type');

    if (contentType && contentType.indexOf('application/json') !== -1) {
      return response.json().then((error) => {
        throw convertError(error, response);
      });
    } else {
      return response.text().then((error) => {
        throw convertError(error, response);
      });
    }
  }
}

export default function configureRestClient(app) {
  const url = process.env.REACT_APP_API_URL;
  app.configure(restClient(url).fetch(window.fetch, {}, FictoService));
}
