import {
  get,
  set,
  del,
  push,
  update,
  assign,
  insert,
  merge,
  wrap as _wrap
} from 'object-path-immutable';

export const delIn = (obj, ...paths) => {
  return paths.reduce((acc, key) => del(acc, key), obj);
};

export const pushIn = (obj, path, ...values) => {
  return values.reduce((acc, value) => push(acc, path, value), obj);
};

export const getIn = get;
export const setIn = set;
export const updateIn = update;
export const assignIn = assign;
export const insertIn = insert;
export const mergeIn = merge;
export const wrap = _wrap;
