import { useEffect, useMemo, useState, useCallback, useRef } from 'react';
import {
  useLocation as useRouterLocation,
  useHistory as useRouterHistory
} from 'react-router-dom';
import { createBreakpoint } from 'react-use';
import { useQuery, useMutation } from 'react-query';
import { getIn } from 'src/lib/immutable';
import app from 'src/lib/feathers';
import { getOpertators } from 'src/lib/state';
import { parseQuery, stringifyQuery } from 'src/lib/querystring';
import preloadRoute from 'src/lib/preload';

export const useHistory = useRouterHistory;

export const useLocation = () => {
  const location = useRouterLocation();
  return {
    ...location,
    query: parseQuery(location.search)
  };
};

export const usePreloadRoute = (path) => {
  useEffect(() => {
    preloadRoute(path);
  });
};

export const usePreloadRouteHover = ({ preload, onMouseEnter }) => {
  if (!preload) {
    return onMouseEnter;
  }
  return (event) => {
    preloadRoute(preload);
    if (onMouseEnter) {
      return onMouseEnter(event);
    }
  };
};

// Same breakpoints as Bootstrap
const useBreakpointHook = createBreakpoint({
  xs: 0,
  sm: 800,
  md: 1000,
  lg: 1200,
  xl: 1400
});

export const useBreakpoint = (sizes = {}) => {
  const screenSize = useBreakpointHook();

  const isXs = screenSize === 'xs';
  const isSm = screenSize === 'sm';
  const isMd = screenSize === 'md';
  const isLg = screenSize === 'lg';
  const isXl = screenSize === 'xl';

  const xs = isXs || isSm || isMd || isLg || isXl;
  const sm = isSm || isMd || isLg || isXl;
  const md = isMd || isLg || isXl;
  const lg = isLg || isXl;
  const xl = isXl;

  const customSizes = {};

  if (Object.keys(sizes).length) {
    Object.entries(sizes).forEach(([key, values]) => {
      customSizes[key] = values.includes(screenSize);
    });
  }

  return {
    screenSize,
    screenWidth: window.innerWidth,
    isXs,
    isSm,
    isMd,
    isLg,
    isXl,
    xs,
    sm,
    md,
    lg,
    xl,
    ...customSizes
  };
};

export const useStateIn = (initialState) => {
  const [state, setState] = useState(initialState);
  const operators = useMemo(() => getOpertators(setState), [setState]);

  // TODO: Memoize getInState...?
  const getInState = (...args) => getIn(state, ...args);

  return [
    state,
    {
      setState,
      getInState,
      ...operators
    }
  ];
};

export const useGlobalStateIn = (stateContainer) => {
  const [, render] = useState({});

  useEffect(() => {
    return stateContainer.subscribe(() => render({}));
  }, [stateContainer]);

  const { listeners, subscribe, state, ...operators } = stateContainer;

  return [state, operators];
};

// Use qs to parse/stringify params. This is "pretty" in the
// URL and is basically a standard but faces all the
// same issues query strings always have like null handling,
// type coercion, etc.
// ?mynum=0123...is that number 123 or '0123' bc of the
// leading 0. And we use numbers and string numbers as ids
// quite often. Long story short, you can't guarantee the
// types of numbers, booleans, dates...because everything
// is a string in the query string
export const useUrlState = (options = {}) => {
  const { navigateMode = 'replace', parseOptions, stringifyOptions } = options;

  const location = useLocation();
  const history = useHistory();

  const urlState = useMemo(() => {
    return parseQuery(location.search, parseOptions);
  }, [location.search, parseOptions]);

  const [state, internalSetState] = useState(urlState);

  const stateRef = useRef(state);
  const locationHashRef = useRef(location.hash);

  useEffect(() => {
    stateRef.current = state;
  }, [state]);

  useEffect(() => {
    locationHashRef.current = location.hash;
  }, [location.hash]);

  const setState = useCallback(
    (newState) => {
      const oldState = stateRef.current;
      const hash = locationHashRef.current;
      const state =
        typeof newState === 'function' ? newState(oldState) : newState;
      history[navigateMode]({
        hash,
        search: state ? stringifyQuery(state, stringifyOptions) : '?'
      });
    },
    [history, navigateMode, stringifyOptions]
  );

  const operators = useMemo(() => getOpertators(setState), [setState]);
  const getInState = (...args) => getIn(state, ...args);

  useEffect(() => {
    internalSetState(urlState);
  }, [urlState]);

  return [
    state,
    {
      setState,
      getInState,
      ...operators
    }
  ];
};

export const useApp = () => {
  return app;
};

export const useAppState = () => {
  const stateContainer = app.get('stateContainer');
  return useGlobalStateIn(stateContainer);
};

export const useFind = (path, params, options) => {
  const { data, ...rest } = useQuery(
    [path, 'find', params],
    () => app.service(path).find(params),
    options
  );
  return {
    result: data,
    ...rest
  };
};

export const useGet = (path, id, params) => {
  const { data, ...rest } = useQuery([path, 'get', id, params], () =>
    app.service(path).get(id, params)
  );
  return {
    result: data,
    ...rest
  };
};

export const useCreate = (path, options) => {
  const { data, ...rest } = useMutation(
    ({ data, params }) => app.service(path).create(data, params),
    options
  );
  return {
    ...rest,
    create: (data, params) => rest.mutateAsync({ data, params }),
    result: data
  };
};

// export const usePatch = (path, options) => {
//   const { data, ...rest } = useMutation(
//     ({ id, data, params }) => app.service(path).patch(id, data, params),
//     options
//   );
//   return {
//     ...rest,
//     patch: (id, data, params) => rest.mutateAsync({ id, data, params }),
//     result: data
//   };
// };

export const useUpdate = (path, options) => {
  const { data, ...rest } = useMutation(
    ({ id, data, params }) => app.service(path).update(id, data, params),
    options
  );
  return {
    ...rest,
    update: (id, data, params) => rest.mutateAsync({ id, data, params }),
    result: data
  };
};

export const useRemove = (path, options) => {
  const { data, ...rest } = useMutation(
    ({ id, params }) => app.service(path).remove(id, params),
    options
  );
  return {
    ...rest,
    remove: (id, params) => rest.mutateAsync({ id, params }),
    result: data
  };
};
