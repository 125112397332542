import {
  getIn,
  setIn,
  delIn,
  pushIn,
  updateIn,
  assignIn,
  insertIn,
  mergeIn,
  wrap
} from 'src/lib/immutable';

export const getOpertators = (setState) => {
  const assignState = (update) =>
    setState((state) => Object.assign({}, state, update));

  const setInState = (...args) => setState((state) => setIn(state, ...args));

  const delInState = (...args) => setState((state) => delIn(state, ...args));

  const pushInState = (...args) => setState((state) => pushIn(state, ...args));

  const updateInState = (...args) =>
    setState((state) => updateIn(state, ...args));

  const assignInState = (...args) =>
    setState((state) => assignIn(state, ...args));

  const insertInState = (...args) =>
    setState((state) => insertIn(state, ...args));

  const mergeInState = (...args) =>
    setState((state) => mergeIn(state, ...args));

  const opInState = (func) => setState((state) => func(wrap(state)).value());

  return {
    assignState,
    setInState,
    delInState,
    pushInState,
    updateInState,
    assignInState,
    insertInState,
    mergeInState,
    opInState
  };
};

export class StateContainer {
  constructor(initialState) {
    this.state = { ...initialState };
    this.listeners = [];
    this.setState = this.setState.bind(this);
    this.subscribe = this.subscribe.bind(this);

    const operators = getOpertators(this.setState);

    Object.entries(operators).forEach(([methodName, method]) => {
      this[methodName] = method;
    });

    this.getInState = (...args) => getIn(this.state, ...args);
  }

  subscribe(fn) {
    this.listeners.push(fn);
    return () => {
      this.listeners = this.listeners.filter((listener) => listener !== fn);
    };
  }

  setState(newState) {
    const state =
      typeof newState === 'function' ? newState(this.state) : newState;
    this.state = { ...state };
    this.listeners.forEach((listener) => listener());
  }
}
