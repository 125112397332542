import { privateRoutes, publicRoutes } from 'src/routes';

// Use the react-loadable static preload() method
// to load chunks on demand
export default function preloadRoute(path) {
  const paths = Array.isArray(path) ? path : [path];
  paths.forEach((path) => {
    if (publicRoutes[path]) {
      publicRoutes[path].preload();
    }
    if (privateRoutes[path]) {
      privateRoutes[path].preload();
    }
  });
}
