import React, { useState, useEffect } from 'react';

export default function Loader({ text = `Loading...`, delay = 0 }) {
  const [show, setShow] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => setShow(true), delay);
    return () => clearTimeout(timer);
  });

  if (!show) {
    return null;
  }

  return (
    <div className="animated fadeIn">
      <h2>{text}</h2>
    </div>
  );
}
