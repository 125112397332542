export function parseJWT(token) {
  try {
    const decoded = JSON.parse(atob(token.split('.')[1]));
    if (new Date(decoded.exp) < new Date()) {
      return decoded;
    }
    return null;
  } catch (e) {
    return null;
  }
}

// Stop JS execution until page reloads with a promise
// that never resolves.
// Else code continues past setting the new
// href bc the page does not immedietly reload
export function redirect(path) {
  return new Promise(() => {
    window.location.href = path;
  });
}

export function isColor(strColor) {
  const s = new Option().style;
  s.color = strColor;
  return s.color !== '';
}
