const stashTokens = (context) => {
  const { access_token, refresh_token } = context.result;
  window.localStorage.setItem('access_token', access_token);
  window.localStorage.setItem('refresh_token', refresh_token);
  return context;
};

const bustTokens = (context) => {
  window.localStorage.removeItem('access_token');
  window.localStorage.removeItem('refresh_token');
  return context;
};

export default function configure(app) {
  app.service('authenticate').hooks({
    after: {
      create: [stashTokens]
    }
  });

  app.service('authenticate/refresh').hooks({
    after: {
      create: [stashTokens]
    }
  });

  app.service('authenticate/invalidate').hooks({
    after: {
      create: [bustTokens]
    }
  });
}
