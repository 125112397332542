import React from 'react';
import { Container } from 'reactstrap';

export default function NotFound() {
  return (
    <Container className="d-flex align-items-center justify-content-center vh-100">
      <h1>404 Not Found 😭</h1>
    </Container>
  );
}
