import { redirect, parseJWT } from 'src/lib';

export const attachAuthHeader = (context) => {
  const token = window.localStorage.getItem('access_token');
  // const refreshToken = window.localStorage.getItem('refresh_token');
  const decoded = parseJWT(token);
  // TODO: If token is about to expire, use the refresh token to
  // get a new token
  if (decoded) {
    context.params.headers = {
      Authorization: `Bearer ${token}`
    };
  }
  return context;
};

export const redirectUnAuthorized = async (context) => {
  const { code } = context.error;
  const { path } = context;
  if (code && code === 401 && path !== 'authenticate') {
    await redirect('/signin');
  }
  return context;
};
