import React from 'react';
import Loadable from 'react-loadable';
import Loader from 'src/components/Loader';

function PageLoader(props) {
  if (props.error) {
    return (
      <div>
        Error! <button onClick={props.retry}>Retry</button>
      </div>
    );
  } else if (props.pastDelay) {
    return (
      <div className="vh-100 d-flex align-items-center justify-content-center">
        <Loader delay={250} />;
      </div>
    );
  } else {
    return null;
  }
}

function load(options) {
  return Loadable(
    Object.assign(
      {
        loading: PageLoader,
        delay: 200
      },
      options
    )
  );
}

export const publicRoutes = {
  '/signin': load({ loader: () => import('src/pages/SignIn') }),
  '/signin-two-factor': load({
    loader: () => import('src/pages/SignInTwoFactor')
  }),
  '/forgot-password': load({
    loader: () => import('src/pages/ForgotPassword')
  }),
  '/signup': load({ loader: () => import('src/pages/SignUp') })
};

export const privateRoutes = {
  '/dashboard': load({ loader: () => import('src/pages/Dashboard') }),
  '/series': load({ loader: () => import('src/pages/Series') }),
  '/series/:id': load({ loader: () => import('src/pages/SeriesEdit') }),
  '/serie': load({ loader: () => import('src/pages/SeriesCreate') }),
  '/categories': load({ loader: () => import('src/pages/Categories') }),
  '/categories/:id': load({ loader: () => import('src/pages/CategoriesEdit') }),
  '/category': load({
    loader: () => import('src/pages/CategoriesCreate')
  }),
  '/style-guide': load({ loader: () => import('src/pages/StyleGuide') })
};
