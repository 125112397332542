import React from 'react';
import ReactDOM from 'react-dom';
import { Switch, BrowserRouter, Redirect } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Route, PrivateRoute } from 'src/components/Route';
import ErrorBoundry from 'src/components/ErrorBoundry';
import 'src/style/index.scss';
import { publicRoutes, privateRoutes } from 'src/routes';
// import reportWebVitals from './reportWebVitals';

import NotFound from 'src/pages/NotFound';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: (failureCount, error) => {
        // failureCount starts at 0. Retry 3 times max
        if (failureCount === 2) {
          return false;
        }

        // 408 === timeout
        if (error.code === 408) {
          return true;
        }

        // TODO: Better detect network/socket errors

        // By default, do not retry. We trust that if the server
        // gave us an error, and it was not an error handled above
        // that we do not need to retry. This handles stuff like 404
        return false;
      }
    }
  }
});

ReactDOM.render(
  <React.StrictMode>
    <ErrorBoundry>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Switch>
            {Object.entries(privateRoutes).map(([path, Component]) => {
              return (
                <PrivateRoute exact path={path} key={path}>
                  <Component />
                </PrivateRoute>
              );
            })}
            {Object.entries(publicRoutes).map(([path, Component]) => {
              return (
                <Route exact path={path} key={path}>
                  <Component />
                </Route>
              );
            })}
            <Route exact path="/">
              <Redirect to="/dashboard" />
            </Route>
            <Route path="*">
              <NotFound />
            </Route>
          </Switch>
        </BrowserRouter>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </ErrorBoundry>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
