import qs from 'qs';

const parseOptions = {
  arrayLimit: 100,
  depth: 20,
  parameterLimit: 2000,
  ignoreQueryPrefix: true,
  strictNullHandling: true
};

const stringifyOptions = {
  arrayLimit: 100,
  depth: 20,
  parameterLimit: 2000,
  strictNullHandling: true,
  // encodeValuesOnly: true,
  encode: false
};

export const parseQuery = (queryString, options) => {
  return qs.parse(queryString, { ...parseOptions, ...options });
};

export const stringifyQuery = (queryObject, options) => {
  return qs.stringify(queryObject, { ...stringifyOptions, ...options });
};
